//***************************************************
//
// Export constants
//
//***************************************************
export {
  SYSTEM_PROVIDER,
  API_ENDPOINT,
  SITE_API_ENDPOINT,
  LIFF_ID,
  ADMIN_SCHEDULE,
  LAYZLOAD_IMG
}

const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
  'https://api.finebooking.shop' : 'http://api.localhost:8081'

const SITE_API_ENDPOINT = process.env.NODE_ENV === 'production' ?
  'https://site-api.finebooking.shop' : 'http://site-api.localhost:8082'

const LIFF_ID = process.env.NODE_ENV === 'production' ?
  '1654462880-maD3V8eO' : '1654462880-2O57yG1b'

const SYSTEM_PROVIDER = {
  name: 'RB TECHNOLOGY, Inc.',
  since: '2021',
  systemName: 'FINEBOOKING'
}

const ADMIN_SCHEDULE = {
  fcalDisplayRange: 1, //1日分
  fcalMinimumShiftMins: 180 //最短出勤時間
}

const LAYZLOAD_IMG = 'assets/lazy-src.png'


//
// default
//
export default {
  MESSAGE: {
    // Plan lockout message
    availableForPaidPlan: "こちらの機能は月額有料のプランでのみご利用可能となっております。<br />"+
                          "プランの変更をご希望の店舗様は「店舗基本設定」→「ご契約プラン」からお申し込みください。",
    availableForPaidPlanShort: "こちらの機能は月額有料プランでのみ利用可能です",

    // Loader message
    loaderLoading: "データ取得中・・・",
    loaderInTarnsaction: "処理中・・・",

    // General error
    programError: 'ネットワークエラーが発生しました。\n同じエラーが何度も出る場合は管理者に連絡してください。\n',
    serverError: 'サーバーエラーが発生しました。\n同じエラーが何度も出る場合は管理者に連絡してください。\n',
    failedShopData: '店舗情報取得中にエラーが発生しました。\nもう一度試してください。\n',

    // Message for vuetify snackbar
    validationFormInput: '未記入項目やエラー項目がないかご確認ください',
    successCreateSubmit: '登録が完了しました',
    successUpdateSubmit: '更新が完了しました',
    successDeleteSubmit: '削除が完了しました',
    successSendSubmit: '送信完了しました',

    infoNoRowsAffected: '情報が変更されていません',
    infoNomoreRecordExists: '読み込むデータがありません',
    infoNoCastsExists: 'キャストデータがありません',
    infoNoCustomerExists: '入力した番号に該当する会員情報はありません',
    infoMaxedupRows: '登録可能な上限に達しました',
    infoAllRankingsOcupied: '全てのランキングが埋まっているので先に既存のランキングを解除してください',
    infoNotifyConfirmOrder: 'ツールバーの「並び登録」ボタンで変更を登録してください',
    infoNoTargetToCast: '配信対象が存在しません',
    infoDontForgetToApply: '変更の登録をお忘れなく',
    infoDataExists: '入力した値は既に登録されています',
    infoNameExists: '入力した名前は既に登録されています',
    infoSearchCondition: '条件を入力して検索ボタンを押してください',

    warningUpdateOrderBeforeCreate: 'すべてのデータを登録してから並び順を更新してください',
    warningNoRankingData: '登録するランキングを付けてください',
    warningInvalidDateRange: '日付の入力値が正しいかご確認ください',
    warningMaxSelectableItems: '選択可能な数を超えています',
    warningMaxRegisterableItems: '既に登録可能な数が登録されています',
    warningCreateBasicInfoFirst: '先に基本情報を登録してください',
    warningNoLineId: 'LINE識別子が未登録のためメッセージが送れません',
    warningShopInfoUnableToEdit: 'こちらの情報は変更できません',
    warningBizHourLessThan24H: '営業時間は24時間未満にしてください',
    warningBizHourMoreThan3H: '営業時間は3時間以上にしてください',
    warningExistsSameLoginId: '既に登録されているIDなので別のLINEアカウントを追加してください',
    warningUnableToDeleteLastAccount: '最後のひとつのアカウントは削除できません',
    warningMaxMulticastUsers: '一度に配信可能なアカウント数の上限を超えています',
    warningNoSearchCondition: '範囲日付かその他の検索条件をひとつ以上入力してください',
    warningTextLengthOver: '入力可能な文字数を超えています',

    failedGetBoilerplate: '定型文の取得に失敗しました',
    errorLinecastBubbleOver: '配信メッセージ内のバブル数は5以下にしてください',

    // Signup form
    validationSingupAgreeTerms: 'ご利用登録には利用規約への同意が必要になります',
    validationSignupSameShopName: '同地域にて同名の店舗が既に使用されています',
    validationSignupSameLoginId: '入力したメールアドレスは既に使用されています',
    validationSignupSameUrl: '入力したURLは既に使用されています',
    failedConfirmSignup: '利用登録処理にてエラーが発生しました。\nもう一度メール内のリンクを試して再度エラーが出る様でしたらお手数ですがお問合わせフォームよりお問い合わせください。',
    failedConfirmSignupExpired: 'リンクの有効期限が切れています。\nアカウントの有効がまだでしたら申し訳ありませんが再度利用登録からお願いいたします。',
    failedSendMail: 'メールの送信に失敗しました。',
    failedSendActivateAMail: '入力したメールアドレスが無効なため確認メールを送信出来ませんでした。\nアカウントを有効化するためにはシステムサポートまでご連絡ください。',
    failedSendInquiryMail: '入力したメールアドレスが無効なためお問い合わせを送信できませんでした。',

    // Validation Schedule form error
    validationInvalidValue: '入力内容にエラーがあります。',
    validationNonNumeric: '数字を入力してください。',
    validationInvalidInputDate: '入力した日付が不正です。',
    validationStartEndTime: '「開始時刻 < 終了時刻」となるように入力してください。',
    validationShiftHoursTooShort: '出勤時間が短すぎるようです。',
    validationFcShiftOutOfBizHour: '出勤時間は営業時間内を選んでください。',
    validationFcSlotStartOutOfBizHour: '営業時間外からの開始は出来ません。',
    validationFcBookingOutOfShiftHour: '予約開始時刻は出勤時間内を選んでください。',
    validationFcCastAlreadyShiftIn: '選択したキャストの出勤情報は既に登録されています。',
    validationFcUnableDeleteShiftWithBooking: '予約が入っているため出勤情報を削除できません。',

    // Auth error
    warningShopDataChanged: '店舗情報を変更したのでログインし直してください。',
    validationLoginCreadential: 'ログインIDとパスワードを入力してください。',
    failedAuthInitialization: 'LINE認証システム初期化中にエラーが発生しました。\n',
    failedAuthorization: '認証情報が間違っているようです。\n認証情報が登録済みの物か確認してください。\n',
    tokenExpired: 'サーバー接続用のセキュリティ情報が古くなっています。\nログインしなおしてください。',
    tokenInvalid: 'サーバー接続用のセキュリティ情報が無効です。\n正規のアクセス方法でシステムをご利用ください。',

    // API request error
    failedApiRequest: 'サーバーリクエスト中にエラー発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiDataNotFound: '対象のデータが存在しないようです。\n条件を変えて試してください。\n',
    failedApiGet: '情報取得中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiCreate: '情報登録中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiUpdate: '情報更新中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiDelete: '情報削除中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiGetResource: 'キャストデータ取得中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',
    failedApiGetEvent: 'スケジュールデータ取得中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n',

    // LINE cast
    unsetLineIntegration: 'LINE連携が未設定です',
    failedLineProfile: 'プロフィール取得中にエラーが発生しました。\nもう一度ログインを試してください。\n',
    failedLineTargetreach: '配信は完了しましたが配信後のターゲットリーチの取得に失敗しました。\n',
    failedLineBroadcast: 'エラーが発生したため配信中止しました。\nしばらくしてからもう一度試してください。\n',
    failedPushMessageFailed: 'LINEメッセージの送信に失敗しました。\n店舗アカウントを友だち解除さている可能性があるので公式アカウントアプリから該当のお客様が友だちである事をご確認ください。\n',

    // File upload
    failedUploadImage: 'アップロードしたファイルに問題があります。\n別のファイルをで再度試してください。\n',
    failedUploadCsv: 'アップロードしたCSVファイルに問題があります。\nCSVフォーマットが正しいかご確認ください。\n',
    validationFileSize: 'ファイルのサイズは1MB以下にしてください。',
    validationFileType: 'ファイルは jpg 画像か png 画像のみ使用可能です',
    validationCustomerNA: '入力した番号に該当する会員情報がありません。\n情報を結びつけたい場合は先にお客様台帳で会員情報を登録をしてください。',

    // Mailmagazine
    failedSendMailMagazine: 'メールマガジンの送信に失敗しました。しばらくしてからもう一度試してください。\n',
  },

  // HTTP Status code
  STATUS_CODE: {
    unauthorized: 401,
    errServer: 600,
    errIssueTokenFailed: 610,
    errVerifyTokenFailed: 611,
    errTokenExpired: 612,
    errTokenInvalid: 613,
    errTokenNotFound: 614,
    errConfirmSignupExpired: 616,
    errJsonmarshalfailed: 620,
    errFileuploadfailed: 630,
    errSendMailFailed: 632,
    errCsvReaderFailed: 634,
    errSendMailmagaFailed: 635,
    errSendActivateMailFailed: 636,
    errSendInquiryMailFailed: 637,
    // DB error
    dbErr: 700,
    dbErrUnintendedRowsAffected: 701,
    dbErrNoRowsAffected: 707,
    dbErrNoTargetToCast: 708,
    dbErrNoRecordExist: 709,
    // LINE related error
    lineErr: 800,
    lineErrApiCallFailed: 801,
    lineErrInitiationfailed: 802,
    lineErrBroadcastfailed: 803,
    lineErrInsightfailed: 804,
    lineErrPushMessageFailed: 807
  },

  LINE_NOPROF_PIC: '@/assets/line-noprofile.jpg'
}
