<template>
  <v-menu
    left bottom
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="accent">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list
      class="flex-column pa-0"
      :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
    >
      <v-list-item text>
        <v-icon
          class="mr-2"
        >mdi-account</v-icon>
        <span
          class="text-caption"
        >{{ this.shopData.shop_name }}</span>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-switch
          class="my-2"
          v-model="$vuetify.theme.dark"
          inset
          dense
          hide-details
          :prepend-icon="$vuetify.theme.dark ? 'mdi-bat' : 'mdi-weather-sunny'"
          label="夜モード"
        ></v-switch>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        text
        @click="$emit('logout')"
      >
        <v-icon class="mr-2">mdi-logout</v-icon>
        <span class="text-caption">ログアウト</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  components: {
  },

  props: {
    shopData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
    }
  },

  computed: {
  }
}
</script>

<style scoped>
.logo-font {
  font-size: 1.2em;
}
.theme--dark.v-menu__content {
  border: thin solid var(--content-border-dark);
  box-shadow: 0px 0px 5px 3px rgb(150 150 150 / 50%);
}
>>> .v-input--switch .v-label {
  font-weight: 600;
  font-size: .75rem;
  font-family: "Roboto", sans-serif;
}
</style>
