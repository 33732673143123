<template>
  <v-sheet class="transparent text-caption text-center">
    <span class="accent--text">&copy; {{ (new Date()).getFullYear() }}</span>
    <span class="logo-font accent--text ml-2">{{ systemName }}</span>
  </v-sheet>
</template>

<script>
import { SYSTEM_PROVIDER } from "@/literals.js";

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
      systemName: SYSTEM_PROVIDER.systemName
    };
  },

  computed: {
  },

  created() {
  }
};
</script>

<style scoped>
</style>
