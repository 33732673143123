<template>
  <v-sheet class="px-5 py-7">
    <v-form ref="form"
      style="width: 350px"
    >
      <v-text-field
        class="pt-0"
        v-model.trim="credential.loginId"
        required autofocus
        :rules="[valiRules.required, valiRules.max50]"
        label="ログインID"
        autocomplete="on"
      ></v-text-field>

      <v-text-field
        class="pt-2"
        v-model.trim="credential.password"
        required
        :rules="[valiRules.required, valiRules.max15]"
        label="パスワード"
        :append-icon="credential.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="credential.showPassword ? 'text' : 'password'"
        @click:append="credential.showPassword = !credential.showPassword"
        autocomplete="on"
      ></v-text-field>

      <div class="d-flex align-center">
        <!-- <v-switch
          class="ma-0 pa-0"
          v-model="keepLogin"
          hide-details
          label="ログイン保持"
        ></v-switch>
        <v-spacer></v-spacer> -->
        <v-btn
          class="ml-n3"
          text small
          :to="{name: 'passwordInquiry'}"
        >
          <v-icon
            class="mt-n1 mr-1"
            small
            color="primary"
          >mdi-lightbulb-on-outline</v-icon>
          パスワードを忘れた
        </v-btn>
      </div>

      <div class="mt-5 d-flex align-center">
        <v-btn
          class="font-weight-bold"
          depressed
          color="primary"
          @click="basicLogin"
        >
          ログイン
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            class="font-weight-bold white--text"
            depressed
            color="#00c300"
            @click="$emit('lineLogin')"
          >
            <span class="logo-font">LINE</span>で簡単ログイン
          </v-btn>
        </div>
      </div>
    </v-form>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      absolute top
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-sheet>
</template>

<script>
import $literals from '@/literals.js'
import { ValidationRules } from '@/module.js'

export default {
  components: {},

  props: {
  },

  data() {
    return {
      credential: { loginId: null, password: null, showPassword: false },
      keepLogin: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    }
  },

  created() {
    this.credential.loginId = this.$route.query.loginId
  },

  methods: {
    basicLogin() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      sessionStorage.setItem('redirectTo', '/shop-admin/dashboard')
      this.$emit('basicLogin', this.credential)
    }
  }
}
</script>

<style scoped>
>>> .v-label {
  font-weight: normal !important;
}
</style>
