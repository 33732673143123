<template>
  <v-overlay :absolute="absolute" :value="loading">
    <v-card>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-progress-linear
        indeterminate
        absolute top
        color="primary"
      ></v-progress-linear>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: true
    },
    absolute: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {};
  },

  created() {}
};
</script>

<style scoped>
</style>
