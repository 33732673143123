export {
  SITE_UPDATE_MENUS,
  SITE_SETTING_MENUS,
  SHOP_ADMIN_MENUS,
  SHOP_SETTING_MENUS,
}

//
//店舗管理 - icon account-tie
//
const SHOP_ADMIN_MENUS =
[
  {
    id: 1,
    title: 'ダッシュボード',
    name: 'shopAdminDashboard',
    slug: 'shop-admin/dashboard',
    iconName: 'chart-line',
    lockAt: []
  },
  {
    id: 4,
    title: 'キャスト管理',
    name: 'shopAdminCast',
    slug: 'shop-admin/cast',
    iconName: 'face-woman-outline',
    lockAt: []
  },
  {
    id: 3,
    title: '出勤管理',
    name: 'shopAdminShift',
    slug: 'shop-admin/shift',
    iconName: 'calendar-clock-outline',
    lockAt: []
  },
  {
    id: 2,
    title: '予約管理シート',
    name: 'shopAdminSchedule',
    slug: 'shop-admin/schedule',
    iconName: 'table-large-plus',
    lockAt: [1]
  },
  {
    id: 5,
    title: '顧客台帳',
    name: 'shopAdminCustomer',
    slug: 'shop-admin/customer',
    iconName: 'face-agent',
    lockAt: [1]
  },
  {
    id: 7,
    title: '予約台帳',
    name: 'shopAdminBooking',
    slug: 'shop-admin/booking',
    iconName: 'book-open-variant',
    lockAt: [1]
  },
  {
    id: 10,
    title: '売上台帳',
    name: 'shopAdminSalesLedger',
    slug: 'shop-admin/sales-ledger',
    iconName: 'cash-register',
    lockAt: [1]
  },
  {
    id: 11,
    title: 'メルマガ配信',
    name: 'shopAdminMailMagazine',
    slug: 'shop-admin/mail-magazine',
    iconName: 'email-send-outline',
    lockAt: []
  },
  {
    id: 8,
    title: 'LINE配信',
    name: 'shopAdminLinecast',
    slug: 'shop-admin/linecast',
    iconName: 'cellphone-nfc',
    lockAt: [1]
  },
  {
    id: 9,
    title: 'クーポン配信',
    name: 'shopAdminCoupon',
    slug: 'shop-admin/coupon',
    iconName: 'ticket-percent-outline',
    lockAt: [1]
  },
]

//
//HP情報更新 - icon monitor-dashboard
//
const SITE_UPDATE_MENUS =
[
  {
    id: 1,
    title: '新着情報',
    name: 'siteUpdateNews',
    slug: 'site-update/news',
    iconName: 'newspaper-plus',
    lockAt: []
  },
  {
    id: 2,
    title: 'イベント情報',
    name: 'siteUpdateEvent',
    slug: 'site-update/event',
    iconName: 'calendar-star',
    lockAt: []
  },
  {
    id: 3,
    title: 'キャストランキング',
    name: 'siteUpdateCastRanking',
    slug: 'site-update/cast-ranking',
    iconName: 'chess-queen',
    lockAt: []
  },
  {
    id: 6,
    title: 'ピックアップ動画',
    name: 'siteUpdateVideo',
    slug: 'site-update/video',
    iconName: 'video',
    lockAt: []
  },
  {
    id: 7,
    title: '写メ日記',
    name: 'siteUpdateDiary',
    slug: 'site-update/diary',
    iconName: 'camera',
    lockAt: []
  },
  {
    id: 4,
    title: '画像スライダー',
    name: 'siteUpdateBannerSlider',
    slug: 'site-update/banner-slider',
    iconName: 'image-multiple-outline',
    lockAt: []
  },
  {
    id: 12,
    title: '画像バナーパーツ',
    name: 'siteDesignBanner',
    slug: 'site-design/part-banner',
    iconName: 'image-size-select-actual',
    lockAt: []
  },
  {
    id: 13,
    title: 'ウィジェットパーツ',
    name: 'siteDesignWidget',
    slug: 'site-design/part-widget',
    iconName: 'code-tags',
    lockAt: []
  },
  {
    id: 10,
    title: 'ホーム画面編集',
    name: 'siteDesignHomePage',
    slug: 'site-design/home-page',
    iconName: 'home',
    lockAt: []
  },
  {
    id: 11,
    title: 'キャストページ編集',
    name: 'siteDesignProfilePage',
    slug: 'site-design/profile-page',
    iconName: 'card-account-details-outline',
    lockAt: []
  },
  {
    id: 9,
    title: '年齢認証ページ編集',
    name: 'siteDesignTopPage',
    slug: 'site-design/top-page',
    iconName: 'door-open',
    lockAt: []
  },
  {
    id: 8,
    title: 'HP基本デザイン',
    name: 'siteDesignOverall',
    slug: 'site-design/overall',
    iconName: 'palette',
    lockAt: []
  },
]

//
//HP設定 - icon clipboard-list-outline
//
const SITE_SETTING_MENUS =
[
  {
    id: 1,
    title: 'HPメニュー',
    name: 'siteAdminMenu',
    slug: 'site-admin/menu',
    iconName: 'menu',
    lockAt: []
  },
  {
    id: 2,
    title: 'コースと料金',
    name: 'siteAdminCourse',
    slug: 'site-admin/course',
    iconName: 'cash-multiple',
    lockAt: []
  },
  {
    id: 3,
    title: '延長料金',
    name: 'siteAdminCourseExtension',
    slug: 'site-admin/course-extension',
    iconName: 'cash-plus',
    lockAt: []
  },
  {
    id: 10,
    title: 'その他料金',
    name: 'siteAdminMiscellaneousFee',
    slug: 'site-admin/miscellaneous-fee',
    iconName: 'cash',
    lockAt: []
  },
  {
    id: 4,
    title: '交通費',
    name: 'siteAdminTravelCharge',
    slug: 'site-admin/travel-charge',
    iconName: 'car',
    lockAt: []
  },
  {
    id: 5,
    title: 'オプション',
    name: 'siteAdminOption',
    slug: 'site-admin/option',
    iconName: 'format-list-bulleted-type',
    lockAt: []
  },
  {
    id: 6,
    title: 'キャストタグ',
    name: 'siteAdminCastTag',
    slug: 'site-admin/cast-tag',
    iconName: 'tag-multiple',
    lockAt: []
  },
  {
    id: 7,
    title: 'キャストランク',
    name: 'siteAdminCastRank',
    slug: 'site-admin/cast-rank',
    iconName: 'triangle-outline',
    lockAt: []
  },
  {
    id: 8,
    title: 'クレジット払い',
    name: 'siteAdminCreditPayment',
    slug: 'site-admin/credit-payment',
    iconName: 'credit-card-check-outline',
    lockAt: []
  },
  {
    id: 9,
    title: 'システム追加項目',
    name: 'siteAdminSystemAdditionalInfo',
    slug: 'site-admin/system-additional-info',
    iconName: 'playlist-plus',
    lockAt: []
  },
]

//
//店舗基本設定 - icon cog
//
const SHOP_SETTING_MENUS =
[
  {
    id: 1,
    title: '店舗基本情報',
    name: 'shopSetting',
    slug: 'shop-setting/basics',
    iconName: 'storefront-outline',
    lockAt: []
  },
  {
    id: 4,
    title: 'アカウント管理',
    name: 'shopSettingAccount',
    slug: 'shop-setting/account',
    iconName: 'account-key',
    lockAt: []
  },
  {
    id: 9,
    title: 'メルアド管理',
    name: 'shopSettingEmail',
    slug: 'shop-setting/email',
    iconName: 'email-plus-outline',
    lockAt: []
  },
  {
    id: 6,
    title: '配信用顧客グループ',
    name: 'shopSettingCustomerGroup',
    slug: 'shop-setting/customer-group',
    iconName: 'human-capacity-increase',
    lockAt: [1]
  },
  {
    id: 7,
    title: '利用広告媒体',
    name: 'shopSettingSalesAd',
    slug: 'shop-setting/sales-ad',
    iconName: 'checkbook',
    lockAt: [1]
  },
  {
    id: 2,
    title: 'LINE連携',
    name: 'shopSettingLine',
    slug: 'shop-setting/line-integration',
    iconName: 'handshake-outline',
    lockAt: [1]
  },
  {
    id: 8,
    title: 'ツイッター連携',
    name: 'shopSettingTwitter',
    slug: 'shop-setting/twitter-integration',
    iconName: 'twitter',
    lockAt: [1]
  },
  {
    id: 5,
    title: '店舗管理情報',
    name: 'shopSettingAdmin',
    slug: 'shop-setting/admin-info',
    iconName: 'card-account-phone',
    lockAt: []
  },
  {
    id: 3,
    title: 'ご契約プラン',
    name: 'shopSettingContract',
    slug: 'shop-setting/contract',
    iconName: 'file-document-edit-outline',
    lockAt: []
  },
]
