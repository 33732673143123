<template>
  <v-container fluid
    v-if="openModal"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openModal"
        max-width="400"
        persistent
      >
        <v-form @submit.prevent="formSubmitted">
          <v-card class="pa-5">
            <v-card-title>
              管理する店舗を選択してください
            </v-card-title>

            <v-select
              ref="shopSelectbox"
              v-model="selectedShop"
              label="店舗一覧"
              :items="shops"
              return-object
              item-text="shop_name"
              item-color="primary"
            ></v-select>

            <v-card-actions class="mt-7 pa-0">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formSubmitted"
              >
                決定
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      shops: [],
      selectedShop: {},
      openModal: false,
    }
  },
  methods: {
    open(loadedShops) {
      this.selectedShop = loadedShops[0]
      this.shops = loadedShops
      this.openModal = true
      this.$nextTick(() => this.$refs.shopSelectbox.focus())
    },
    formSubmitted() {
      if (this.selectedShop.shop_id) {
        this.$emit('form-submitted', this.selectedShop.shop_id)
      }
      this.openModal = false
    }
  }
}
</script>

<style scoped>
</style>
