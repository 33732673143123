<template>
  <v-footer app
    absolute
    color="primary accent--text"
  >
    <v-row no-gutters>
      <v-col cols="12"
        class="text-center"
      >
        <v-btn class="my-1"
          v-for="item in singlePages"
          :key="item.id"
          color="accent"
          text rounded
          :to="{name: item.name}"
        >
          {{ item.label }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <copy-right class=""></copy-right>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import CopyRight from '@/components/_CopyRight.vue'

export default {
  components: {
    'copy-right': CopyRight,
  },

  data() {
    return {
      singlePages: [
        {id: 1, name: 'applicationProcedure', label: 'ご利用の流れ'},
        {id: 2, name: 'termsOfUse', label: '利用規約'},
        {id: 3, name: 'privacyPolicy', label: 'プライバシーポリシー'},
        {id: 4, name: 'inquiryPage', label: 'お問い合わせ'},
      ]
    };
  }
};
</script>

<style scoped>
.v-footer--absolute, .v-footer--fixed {
  z-index: 6;
}
</style>
