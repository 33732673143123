<template>
  <v-navigation-drawer app
    width="220"
    permanent
    :mini-variant.sync="openDrawer"
  >
    <v-sheet
      class="menu-header px-4 d-flex align-center"
      :class="{'pl-2': openDrawer}"
    >
      <v-btn
        icon
        @click="openDrawer = !openDrawer"
      >
        <v-icon
          v-if="openDrawer"
          color="accent"
          v-text="mdiIconName('hamburger')">
        </v-icon>
        <v-icon
          v-else
          color="accent"
          v-text="mdiIconName('backburger')">
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <system-menu
        :shopData="shopData"
        @logout="$emit('logout')"
      ></system-menu>
    </v-sheet>

    <v-divider></v-divider>

    <v-list
      class="pt-0"
    >
      <!-- 店舗管理 -->
      <v-list-group
        :value="isItemSelected(shopAdminMenu)"
      >
        <template v-slot:activator>
          <v-list-item-icon class="mr-3">
            <v-icon v-text="mdiIconName('account-tie')"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">店舗管理</span>
          </v-list-item-title>
        </template>

        <v-list-item
          class="pl-6 pr-3"
          v-for="menu in shopAdminMenu"
          :key="menu.id"
          :to="{
            path: '/' + menu.slug,
            params: {
              slug: menu.slug,
              id: menu.id,
              title: menu.title,
              isloggedin: isloggedin
            }
          }"
        >
          <v-list-item-icon class="ml-2 mr-3"
            v-if="lockedMenu(menu.lockAt)"
          >
            <v-icon>mdi-lock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-icon class="ml-2 mr-3"
            v-else
          >
            <v-icon v-text="mdiIconName(menu.iconName)"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline"
              :class="!lockedMenu(menu.lockAt) && 'font-weight-bold'"
            >{{ menu.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <!-- HP管理 -->
      <v-list-group
        :value="isItemSelected(siteUpdateMenu)"
      >
        <template v-slot:activator>
          <v-list-item-icon class="mr-3">
            <v-icon v-text="mdiIconName('monitor-dashboard')"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">ＨＰ管理</span>
          </v-list-item-title>
        </template>

        <v-list-item
          class="pl-6 pr-3"
          v-for="menu in siteUpdateMenu"
          :key="menu.id"
          :to="{
            path: '/' + menu.slug,
            params: {
              slug: menu.slug,
              id: menu.id,
              title: menu.title,
              isloggedin: isloggedin
            }
          }"
        >
          <v-list-item-icon class="ml-2 mr-3">
            <v-icon v-text="mdiIconName(menu.iconName)"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">{{ menu.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <!-- HP基本設定 -->
      <v-list-group
        :value="isItemSelected(siteSettingMenu)"
      >
        <template v-slot:activator>
          <v-list-item-icon class="mr-3">
            <v-icon v-text="mdiIconName('application-cog')"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">ＨＰ基本設定</span>
          </v-list-item-title>
        </template>

        <v-list-item
          class="pl-6 pr-3"
          v-for="menu in siteSettingMenu"
          :key="menu.id"
          :to="{
            path: '/' + menu.slug,
            params: {
              slug: menu.slug,
              id: menu.id,
              title: menu.title,
              isloggedin: isloggedin
            }
          }"
        >
          <v-list-item-icon class="ml-2 mr-3">
            <v-icon v-text="mdiIconName(menu.iconName)"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">{{ menu.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <!-- 店舗基本設定 -->
      <v-list-group
        :value="isItemSelected(shopSettingMenu)"
      >
        <template v-slot:activator>
          <v-list-item-icon class="mr-3">
            <v-icon v-text="mdiIconName('cog')"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline font-weight-bold">店舗基本設定</span>
          </v-list-item-title>
        </template>

        <v-list-item
          class="pl-6"
          v-for="menu in shopSettingMenu"
          :key="menu.id"
          :to="{
            path: '/' + menu.slug,
            params: {
              slug: menu.slug,
              id: menu.id,
              title: menu.title,
              isloggedin: isloggedin
            }
          }"
        >
          <v-list-item-icon class="ml-2 mr-3"
            v-if="lockedMenu(menu.lockAt)"
          >
            <v-icon>mdi-lock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-icon class="ml-2 mr-3"
            v-else
          >
            <v-icon v-text="mdiIconName(menu.iconName)"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="text-overline"
              :class="!lockedMenu(menu.lockAt) && 'font-weight-bold'"
            >{{ menu.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-sheet class="mt-auto px-3 pb-1"
      color="primary"
      v-if="!openDrawer"
    >
      <!-- ロゴ -->
      <router-link :to="{ name: 'home' }">
        <v-img
          class="px-10 rounded"
          contain
          src="@/assets/logo_mini.png"
        ></v-img>
      </router-link>

      <v-card class="notification-window px-2 py-1" outlined>
        <h5 class="pb-2 text-caption text-center font-weight-bold">システムに関するお知らせ</h5>
        <v-card-text class="pa-1"
          v-for="row in notificationRows"
          :key="row.id"
        >
          <p class="text-body-2" v-html="row.content"></p>
          <v-divider class="my-3"></v-divider>
        </v-card-text>
      </v-card>
      <copy-right class="mt-3"></copy-right>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import {
  SITE_UPDATE_MENUS,
  SITE_SETTING_MENUS,
  SHOP_ADMIN_MENUS,
  SHOP_SETTING_MENUS
} from '@/menus.js'
import { API_ENDPOINT } from "@/literals.js";
import { ApiTool, CheckTokenError } from '@/module.js'
import SystemDotMenu from '@/components/_SystemDotMenu.vue'
import CopyRight from '@/components/_CopyRight.vue'

export default {
  components: {
    'system-menu': SystemDotMenu,
    'copy-right': CopyRight,
  },

  props: {
    isloggedin: {
      type: Boolean,
      default: false
    },
    isLiff: {
      type: Boolean,
      default: false
    },
    userProfile: {
      type: Object,
      default: () => ({})
    },
    shopData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      openDrawer: false,
      siteUpdateMenu: SITE_UPDATE_MENUS,
      siteSettingMenu: SITE_SETTING_MENUS,
      shopAdminMenu: SHOP_ADMIN_MENUS,
      shopSettingMenu: SHOP_SETTING_MENUS,
      notificationRows: [],
      publicApi: new ApiTool(API_ENDPOINT + '/'),
    }
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    },
    isItemSelected() {
      return menus => menus.some( item => item.name === this.$route.name )
    },
    lockedMenu() {
      return lockAt => lockAt.some( planId => planId === this.shopData.system_plan_id )
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown': function(now) {
      if (now) this.openDrawer = true
    }
  },

  created() {
    this.openDrawer = this.$vuetify.breakpoint.smAndDown

    this.setSystemNotification()
  },

  methods: {
    setSystemNotification() {
      return this.publicApi.getReqSystemPublic('system-notification/').then( results => {
        if (!results || !results.length) return

        this.notificationRows = results
      })
      .catch(error => CheckTokenError(error))
    },
  }
}
</script>

<style scoped>
.theme--dark.v-navigation-drawer {
  box-shadow: 0px 0px 7px 3px rgb(150 150 150 / 30%);
  background-color: #171717;
}
.v-navigation-drawer {
  max-height: 100% !important;
}
>>> .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
.menu-header {
  height: 48px !important;
}
.theme--dark.menu-header {
  background-color: #101010;
}
>>> .v-list-group__header__append-icon {
  margin: 0 !important;
}
>>> .v-list-item__icon {
  margin: 10px 0;
}
>>> .v-list-group__header {
  margin: 0 12px;
  padding: 0 4px;
  border-bottom: thin solid grey;
}
.v-navigation-drawer--mini-variant .v-list-item {
  padding-left: 8px !important;
}
.notification-window {
  max-height: 150px;
  overflow-y: scroll;
}
</style>
