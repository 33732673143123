var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"primary"}},[_c('v-sheet',{staticClass:"d-flex align-center flex-grow-1",attrs:{"flat":"","color":"transparent"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('v-img',{staticClass:"ml-2 mr-5 rounded-sm",class:hover && 'elevation-1',attrs:{"contain":"","width":"65","src":require("@/assets/logo_mini.png"),"alt":_vm.systemName}})],1)]}}])}),(_vm.$route.name == 'home' && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-tabs',{attrs:{"slider-color":"accent","background-color":"primary"}},[_c('v-tab',{staticClass:"white--text",attrs:{"to":{name: 'home'}}},[_vm._v("トップ")]),_c('v-tab',{staticClass:"white--text",attrs:{"to":{hash: '#features'}}},[_vm._v("特徴＆機能")]),_c('v-tab',{staticClass:"white--text",attrs:{"to":{hash: '#plans'}}},[_vm._v("料金プラン")]),_c('v-tab',{staticClass:"white--text",attrs:{"to":{name: 'applicationProcedure'}}},[_vm._v("ご利用の流れ")]),_c('v-tab',{staticClass:"white--text",attrs:{"to":{hash: '#support'}}},[_vm._v("サポート")])],1):_vm._e(),(_vm.title && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-button white--text"},[_vm._v(_vm._s(_vm.title))])],1):_vm._e(),_c('v-spacer'),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleAccountName),expression:"toggleAccountName"}],staticClass:"ma-0 px-3 py-1 flex-shrink-1 accent primary--text rounded text-subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.shopData.shop_name)+" ")]),(!_vm.isloggedin)?_c('div',[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 py-5 text-caption font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-6',attrs:{"small":"","depressed":"","color":"accent"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-login")]),_vm._v(" ログイン ")],1)]}}],null,false,3280968364),model:{value:(_vm.openFormLogin),callback:function ($$v) {_vm.openFormLogin=$$v},expression:"openFormLogin"}},[(!_vm.isLiff)?_c('form-login',{attrs:{"isloggedin":_vm.isloggedin},on:{"basicLogin":function($event){return _vm.$emit('basicLogin', $event)},"lineLogin":function($event){return _vm.$emit('lineLogin')},"reset":function($event){return _vm.$emit('reset')}}}):_vm._e()],1),_c('v-btn',{staticClass:"py-5 accent--text font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-7',attrs:{"small":"","color":"white","to":{ name: 'signup' }}},[_vm._v(" お申し込み ")])],1):_vm._e(),_c('div',[(_vm.isloggedin)?_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2330095289)},[(_vm.isloggedin)?_c('v-list',{staticClass:"flex-column pa-0",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-4' : 'white'}},[_c('v-list-item',{attrs:{"text":""},on:{"click":function($event){_vm.toggleAccountName = !_vm.toggleAccountName}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account")]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(this.shopData.shop_name))])],1),_c('v-divider'),_c('v-list-item',[_c('v-switch',{staticClass:"my-2",attrs:{"inset":"","dense":"","hide-details":"","append-icon":"mdi-bat","label":"夜行モード"},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),_c('v-divider'),_c('v-list-item',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('logout')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-logout")]),_c('span',{staticClass:"text-caption"},[_vm._v("ログアウト")])],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }