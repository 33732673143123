<template>
  <v-app-bar app
    color="primary"
  >
    <v-sheet
      class="d-flex align-center flex-grow-1"
      flat
      color="transparent"
    >
      <!-- ロゴ -->
      <v-hover v-slot="{ hover }">
        <router-link
          :to="{ name: 'home' }"
        >
          <v-img
            class="ml-2 mr-5 rounded-sm"
            :class="hover && 'elevation-1'"
            contain
            width="65"
            src="@/assets/logo_mini.png"
            :alt="systemName"
          ></v-img>
        </router-link>
      </v-hover>

      <!-- ホーム用#リンク -->
      <v-tabs
        v-if="$route.name == 'home' && $vuetify.breakpoint.mdAndUp"
        slider-color="accent"
        background-color="primary"
      >
        <v-tab class="white--text"
          :to="{name: 'home'}"
        >トップ</v-tab>
        <v-tab class="white--text"
          :to="{hash: '#features'}"
        >特徴＆機能</v-tab>
        <v-tab class="white--text"
          :to="{hash: '#plans'}"
        >料金プラン</v-tab>
        <v-tab class="white--text"
          :to="{name: 'applicationProcedure'}"
        >ご利用の流れ</v-tab>
        <v-tab class="white--text"
          :to="{hash: '#support'}"
        >サポート</v-tab>
      </v-tabs>

      <!-- 個別ページ用タイトル -->
      <v-card v-if="title && $vuetify.breakpoint.mdAndUp"
        flat
        color="transparent"
      >
        <v-card-title class="text-button white--text">{{ title }}</v-card-title>
      </v-card>

      <v-spacer></v-spacer>

      <!-- ログイン情報 -->
      <p
        class="ma-0 px-3 py-1 flex-shrink-1 accent primary--text rounded text-subtitle-2 font-weight-bold"
        v-show="toggleAccountName"
      >
        {{ this.shopData.shop_name }}
      </p>

      <!-- 未ログインメニュー -->
      <div
         v-if="!isloggedin"
      >
        <v-menu
          v-model="openFormLogin"
          left bottom
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-3 py-5 text-caption font-weight-bold"
              :class="$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-6'"
              small depressed
              color="accent"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-login</v-icon>
              ログイン
            </v-btn>
          </template>

          <form-login
            v-if="!isLiff"
            :isloggedin="isloggedin"
            @basicLogin="$emit('basicLogin', $event)"
            @lineLogin="$emit('lineLogin')"
            @reset="$emit('reset')"
          >
          </form-login>
        </v-menu>

        <v-btn class="py-5 accent--text font-weight-bold"
          :class="$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-7'"
          small
          color="white"
          :to="{ name: 'signup' }"
        >
          お申し込み
        </v-btn>
      </div>

      <div>
        <!-- ログイン済みメニュー -->
        <v-menu
          left bottom
          offset-y
          :close-on-content-click="false"
          v-if="isloggedin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="accent">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list
            class="flex-column pa-0"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            v-if="isloggedin"
          >
            <v-list-item
              text
              @click="toggleAccountName = !toggleAccountName"
            >
              <v-icon
                class="mr-2"
              >mdi-account</v-icon>
              <span
                class="text-caption"
              >{{ this.shopData.shop_name }}</span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-switch
                class="my-2"
                v-model="$vuetify.theme.dark"
                inset
                dense
                hide-details
                append-icon="mdi-bat"
                :label="`夜行モード`"
              ></v-switch>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              text
              @click="$emit('logout')"
            >
              <v-icon class="mr-2">mdi-logout</v-icon>
              <span class="text-caption">ログアウト</span>
            </v-list-item>
            <!-- <v-list-item
              text
              @click="$emit('login')"
            >
              <v-icon class="mr-2">mdi-login</v-icon>
              <span class="text-caption">ログイン</span>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
    </v-sheet>
  </v-app-bar>
</template>

<script>
import { SYSTEM_PROVIDER } from "@/literals.js"
import FormLogin from "@/components/_FormLogin.vue"

export default {
  components: {
    "form-login": FormLogin,
  },

  props: {
    isloggedin: {
      type: Boolean,
      default: false
    },
    isLiff: {
      type: Boolean,
      default: false
    },
    userProfile: {
      type: Object,
      default: () => ({})
    },
    shopData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      singlePages: [
        {name: 'signup', label: 'システムご利用のお申し込み'},
        {name: 'signupActivate', label: 'アカウントの有効化'},
        {name: 'applicationProcedure', label: 'ご利用の流れ'},
        {name: 'termsOfUse', label: SYSTEM_PROVIDER.systemName + ' 利用規約'},
        {name: 'privacyPolicy', label: 'プライバシーポリシー'},
        {name: 'inquiryPage', label: 'お問い合わせ'},
      ],
      toggleAccountName: false,
      openFormLogin: false,
      systemName: SYSTEM_PROVIDER.systemName
    }
  },

  computed: {
    userName() {
      return this.isloggedin || "ログインしてください"
    },
    title() {
      const target = this.singlePages.find( page => page.name === this.$route.name )
      return target !== undefined ? target.label : ''
    }
  }
}
</script>

<style scoped>
.logo-font {
  font-size: 1.2em;
}
.theme--dark.v-menu__content {
  border: thin solid var(--content-border-dark);
  box-shadow: 0px 0px 10px 5px rgb(150 150 150 / 50%);
}
>>> .v-input--switch .v-label {
  font-weight: 600;
  font-size: .85rem;
  font-family: "Roboto", sans-serif;
}
.v-tabs {
  width: unset;
}
</style>
