import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'
// import Schedule from '@/views/Schedule.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: 'shop-admin-home' */ '@/views/Home.vue'),
    props: true,
    meta: { requiresAuth: false }
  },
  {
    //ダッシュボード
    path: '/shop-admin/dashboard',
    name: 'shopAdminDashboard',
    component: () => import(/* webpackChunkName: 'shop-admin-dashboard' */ '@/views/ShopAdminDashboard.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //スケジュール帳
    path: '/shop-admin/schedule',
    name: 'shopAdminSchedule',
    component: () => import(/* webpackChunkName: 'shop-admin-schedule' */ '@/views/ShopAdminSchedule.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //出勤台帳
    path: '/shop-admin/shift',
    name: 'shopAdminShift',
    component: () => import(/* webpackChunkName: 'shop-admin-shift' */ '@/views/ShopAdminShiftRecords.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //キャスト台帳
    path: '/shop-admin/cast',
    name: 'shopAdminCast',
    component: () => import(/* webpackChunkName: 'shop-admin-cast' */ '@/views/ShopAdminCast.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //お客様台帳
    path: '/shop-admin/customer',
    name: 'shopAdminCustomer',
    component: () => import(/* webpackChunkName: 'shop-admin-customer' */ '@/views/ShopAdminCustomer.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //予約台帳
    path: '/shop-admin/booking',
    name: 'shopAdminBooking',
    component: () => import(/* webpackChunkName: 'shop-admin-booking' */ '@/views/ShopAdminBookingRecords.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //メルマガ配信
    path: '/shop-admin/mail-magazine',
    name: 'shopAdminMailMagazine',
    component: () => import(/* webpackChunkName: 'shop-admin-mailmaga' */ '@/views/ShopAdminMailMagazine.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //LINE配信
    path: '/shop-admin/linecast',
    name: 'shopAdminLinecast',
    component: () => import(/* webpackChunkName: 'shop-admin-linecast' */ '@/views/ShopAdminLinecast.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //LINEクーポン
    path: '/shop-admin/coupon',
    name: 'shopAdminCoupon',
    component: () => import(/* webpackChunkName: 'shop-admin-coupon' */ '@/views/ShopAdminCoupon.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //売上台帳
    path: '/shop-admin/sales-ledger',
    name: 'shopAdminSalesLedger',
    component: () => import(/* webpackChunkName: 'shop-admin-sales-ledger' */ '@/views/ShopAdminSalesLedger.vue'),
    props: true,
    meta: { requiresAuth: true }
  },

  //***********************************************************
  //HP情報更新
  //***********************************************************
  {
    //新着情報
    path: '/site-update/news',
    name: 'siteUpdateNews',
    component: () => import(/* webpackChunkName: 'site-update-news' */ '@/views/SiteUpdateNews.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //イベント
    path: '/site-update/event',
    name: 'siteUpdateEvent',
    component: () => import(/* webpackChunkName: 'site-update-event' */ '@/views/SiteUpdateEvent.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //キャストランキング
    path: '/site-update/cast-ranking',
    name: 'siteUpdateCastRanking',
    component: () => import(/* webpackChunkName: 'site-update-cast-ranking' */ '@/views/SiteUpdateCastRanking.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //画像スライダー
    path: '/site-update/banner-slider',
    name: 'siteUpdateBannerSlider',
    component: () => import(/* webpackChunkName: 'site-update-banner-slider' */ '@/views/SiteUpdateBannerSlider.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //ピックアップ動画
    path: '/site-update/video',
    name: 'siteUpdateVideo',
    component: () => import(/* webpackChunkName: 'site-update-video' */ '@/views/SiteUpdateVideo.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //写メ日記
    path: '/site-update/diary',
    name: 'siteUpdateDiary',
    component: () => import(/* webpackChunkName: 'site-update-diary' */ '@/views/SiteUpdateDiary.vue'),
    props: true,
    meta: { requiresAuth: true },
  },

  //***********************************************************
  //HPデザイン
  //***********************************************************
  {
    //デザイン編集
    path: '/site-design/overall',
    name: 'siteDesignOverall',
    component: () => import(/* webpackChunkName: 'site-design-overall' */ '@/views/SiteDesignOverall.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //年齢認証画面編集
    path: '/site-design/top-page',
    name: 'siteDesignTopPage',
    component: () => import(/* webpackChunkName: 'site-design-top-page' */ '@/views/SiteDesignTopPage.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //ホーム画面編集
    path: '/site-design/home-page',
    name: 'siteDesignHomePage',
    component: () => import(/* webpackChunkName: 'site-design-home-page' */ '@/views/SiteDesignHomePage.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //プロフィール画面編集
    path: '/site-design/profile-page',
    name: 'siteDesignProfilePage',
    component: () => import(/* webpackChunkName: 'site-design-profile-page' */ '@/views/SiteDesignProfilePage.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //画像バナー
    path: '/site-design/part-banner',
    name: 'siteDesignBanner',
    component: () => import(/* webpackChunkName: 'site-design-banner' */ '@/views/SiteDesignBanner.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //ウィジェット
    path: '/site-design/part-widget',
    name: 'siteDesignWidget',
    component: () => import(/* webpackChunkName: 'site-design-widget' */ '@/views/SiteDesignWidget.vue'),
    props: true,
    meta: { requiresAuth: true },
  },

  //***********************************************************
  //HP管理 - 基本設定
  //***********************************************************
  {
    //HPメニュー
    path: '/site-admin/menu',
    name: 'siteAdminMenu',
    component: () => import(/* webpackChunkName: 'site-admin-menu' */ '@/views/SiteAdminMenu.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //コースと料金
    path: '/site-admin/course',
    name: 'siteAdminCourse',
    component: () => import(/* webpackChunkName: 'site-admin-course' */ '@/views/SiteAdminCourse.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //延長料金
    path: '/site-admin/course-extension',
    name: 'siteAdminCourseExtension',
    component: () => import(/* webpackChunkName: 'site-admin-course-extension' */ '@/views/SiteAdminCourseExtension.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //交通費
    path: '/site-admin/travel-charge',
    name: 'siteAdminTravelCharge',
    component: () => import(/* webpackChunkName: 'site-admin-travel-charge' */ '@/views/SiteAdminTravelCharge.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //細速料金
    path: '/site-admin/miscellaneous-fee',
    name: 'siteAdminMiscellaneousFee',
    component: () => import(/* webpackChunkName: 'site-admin-miscellaneous-fee' */ '@/views/SiteAdminMiscellaneousFee.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //オプション
    path: '/site-admin/option',
    name: 'siteAdminOption',
    component: () => import(/* webpackChunkName: 'site-admin-option' */ '@/views/SiteAdminOption.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //キャストランク
    path: '/site-admin/cast-rank',
    name: 'siteAdminCastRank',
    component: () => import(/* webpackChunkName: 'site-admin-cast-rank' */ '@/views/SiteAdminCastRank.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //キャストタグ
    path: '/site-admin/cast-tag',
    name: 'siteAdminCastTag',
    component: () => import(/* webpackChunkName: 'site-admin-cast-tag' */ '@/views/SiteAdminCastTag.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //クレジット払い
    path: '/site-admin/credit-payment',
    name: 'siteAdminCreditPayment',
    component: () => import(/* webpackChunkName: 'site-admin-credit-payment' */ '@/views/SiteAdminCreditPayment.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    //システム追加項目
    path: '/site-admin/system-additional-info',
    name: 'siteAdminSystemAdditionalInfo',
    component: () => import(/* webpackChunkName: 'site-admin-system-additional-info' */ '@/views/SiteAdminSystemAdditionalInfo.vue'),
    props: true,
    meta: { requiresAuth: true },
  },

  //***********************************************************
  //店舗基本設定
  //***********************************************************
  {
    //店舗基本情報
    path: '/shop-setting/basics',
    name: 'shopSetting',
    component: () => import(/* webpackChunkName: 'shop-setting' */ '@/views/ShopSetting.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //アカウント情報
    path: '/shop-setting/account',
    name: 'shopSettingAccount',
    component: () => import(/* webpackChunkName: 'shop-setting-account' */ '@/views/ShopSettingAccount.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //メルアド管理
    path: '/shop-setting/email',
    name: 'shopSettingEmail',
    component: () => import(/* webpackChunkName: 'shop-setting-email' */ '@/views/ShopSettingEmail.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //LINE連携
    path: '/shop-setting/line-integration',
    name: 'shopSettingLine',
    component: () => import(/* webpackChunkName: 'shop-setting-line' */ '@/views/ShopSettingLine.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //顧客グループ管理
    path: '/shop-setting/customer-group',
    name: 'shopSettingCustomerGroup',
    component: () => import(/* webpackChunkName: 'shop-setting-customer-group' */ '@/views/ShopSettingCustomerGroup.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //利用広告媒体
    path: '/shop-setting/sales-ad',
    name: 'shopSettingSalesAd',
    component: () => import(/* webpackChunkName: 'shop-setting-sales-ad' */ '@/views/ShopSettingSalesAd.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //契約プラン
    path: '/shop-setting/contract',
    name: 'shopSettingContract',
    component: () => import(/* webpackChunkName: 'shop-setting-contract' */ '@/views/ShopSettingContract.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //店舗管理情報
    path: '/shop-setting/admin-info',
    name: 'shopSettingAdmin',
    component: () => import(/* webpackChunkName: 'shop-setting-admin' */ '@/views/ShopSettingAdmin.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //ツイッター連携
    path: '/shop-setting/twitter-integration',
    name: 'shopSettingTwitter',
    component: () => import(/* webpackChunkName: 'shop-setting-twitter' */ '@/views/ShopSettingTwitter.vue'),
    props: true,
    meta: { requiresAuth: true }
  },

  //***********************************************************
  //個別ページ
  //***********************************************************
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import(/* webpackChunkName: 'signup' */ '@/views/_Signup.vue'),
    meta: { requiresAuth: false }
  },
  {
    //サインアップアクティベートページ
    path: '/signup/activate',
    name: 'signupActivate',
    component: () => import(/* webpackChunkName: 'signup-activate' */ '@/views/_SignupActivate.vue'),
    meta: { requiresAuth: false }
  },
  {
    //ご利用の流れ
    path: '/application-procedure',
    name: 'applicationProcedure',
    component: () => import(/* webpackChunkName: 'application-procedure' */ '@/views/_ApplicationProcedure.vue'),
    meta: { requiresAuth: false }
  },
  {
    //利用規約
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: () => import(/* webpackChunkName: 'terms-of-use' */ '@/views/_TermsOfUse.vue'),
    meta: { requiresAuth: false }
  },
  {
    //プライバシーポリシー
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: 'privacy-policy' */ '@/views/_PrivacyPolicy.vue'),
    meta: { requiresAuth: false }
  },
  {
    //お問い合わせ
    path: '/inquiry',
    name: 'inquiryPage',
    component: () => import(/* webpackChunkName: 'inquiry-page' */ '@/views/_InquiryPage.vue'),
    meta: { requiresAuth: false }
  },
  {
    //パスワード忘れ
    path: '/password-inquiry',
    name: 'passwordInquiry',
    component: () => import(/* webpackChunkName: 'password-inquiry' */ '@/views/_PasswordInquiry.vue'),
    meta: { requiresAuth: false }
  },

  //***********************************************************
  // 404
  //***********************************************************
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) return { selector: to.hash, behavior: 'smooth' }
    else return { x: 0, y: 0 }
  },
})

//
//認証必要ページ遷移時に対するグローバルガード
//
router.beforeEach((to, from, next) => {
  // guard for authorized admin pages
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isThereSessionStrage =
      // sessionStorage.getItem('isLINEloggedin') === 'true' &&
      sessionStorage.getItem('serverToken') !== null &&
      sessionStorage.getItem('shopDataArray') !== null &&
      sessionStorage.getItem('selectedShopId') !== null

    if (!isThereSessionStrage) {
      if (to.params.title) {
        alert(to.params.title + 'を使うにはログインが必要です')
      } else {
        alert('こちらのページへのアクセスにはログインが必要です')
      }
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next() // must go thru next() in any route
  }
})

export default router
