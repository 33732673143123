import axios from 'axios'
import moment from 'moment'
import $literals from '@/literals.js'

//***************************************************
//
// Export functions
//
//***************************************************
export {
  ValidationRules,
  CheckTokenError,
  HandleChangeImg,
  HandleChangeMultipleImg,
  ConvertPhone,
  FormDataToObj
}

//***************************************************
// Validation rules for vuetify form inputs
//
const ValidationRules = {
  required: function(val) {
    return !!val || '必須項目です'
  },
  requiredArray: function(val) {
    return val.length > 0 || '必須項目です'
  },
  requiredObj: function(val) {
    return Object.keys(val).length > 0 || '必須項目です'
  },
  minutes: function(val) {
    return (val >= 0 && val < 720) || '[0 ~ 720]の間にしてください'
  },
  price: function(val) {
    return (val >= 0 && val < 1777215) || '[0 ~ 1777215]の間にしてください'
  },
  phone: function(val) {
    if (!val) return true
    return /^\d+$/.test(val.replace(/[\s+\-−]/g, '')) || '電話番号の書式が不正です'
  },
  email: function(val) {
    return /.+@.+\..+/.test(val) || 'メールアドレスの書式が不正です'
  },
  url: function(val) {
    if (!val) return true
    return /^(https|http):\/\/.+\..+/.test(val) || 'アドレスの書式が不正です'
  },
  uniqueInObjs: function(objArray, org, key) {
    return val => {
      if (val === org) return true
      return !objArray.some(obj => obj[key] === val) || `「${val}」は既に使用されています`
    }
  },
  file: function(val) {
    if (!val) return true
    return val.size < 5000000 || 'ファイルサイズは5MB以内にしてください'
  },
  image: function(val) {
    if (!val) return true
    return val.size < 1000000 || '画像のサイズは1MB以内にしてください'
  },
  images: function(files) {
    if (!files.length) return true
    return files.some( f => f.size < 1000000 ) || '各画像のサイズは1MB以内にしてください'
  },
  video: function(val) {
    if (!val) return true
    return val.size < 10000000 || '動画のサイズは10MB以内にしてください'
  },
  age: function(val) {
    if (val < 18) return '正しい年齢を入力してください'
    if (val > 100) return 'Older than 100 years old for this job?!'
    return true
  },
  min1num: function(val) {
    return !val || (val >= 1) || '1以上の値にしてください'
  },
  max10num: function(val) {
    return !val || (val <= 10) || '10以下の値にしてください'
  },
  max200num: function(val) {
    return !val || (val <= 200) || '200以下の値にしてください'
  },
  max8: function(val) {
    return (val || '').length <= 8 || '8文字以内にしてください'
  },
  max10: function(val) {
    return (val || '').length <= 10 || '10文字以内にしてください'
  },
  max15: function(val) {
    return (val || '').length <= 15 || '15文字以内にしてください'
  },
  max20: function(val) {
    return (val || '').length <= 20 || '20文字以内にしてください'
  },
  max30: function(val) {
    return (val || '').length <= 30 || '30文字以内にしてください'
  },
  max50: function(val) {
    return (val || '').length <= 50 || '50文字以内にしてください'
  },
  max60: function(val) {
    return (val || '').length <= 60 || '60文字以内にしてください'
  },
  max100: function(val) {
    return (val || '').length <= 100 || '100文字以内にしてください'
  },
  max150: function(val) {
    return (val || '').length <= 150 || '150文字以内にしてください'
  },
  max200: function(val) {
    return (val || '').length <= 200 || '200文字以内にしてください'
  },
  max300: function(val) {
    return (val || '').length <= 300 || '300文字以内にしてください'
  },
  max500: function(val) {
    return (val || '').length <= 500 || '500文字以内にしてください'
  },
  max1000: function(val) {
    return (val || '').length <= 1000 || '1000文字以内にしてください'
  },
  max2000: function(val) {
    return (val || '').length <= 2000 || '2000文字以内にしてください'
  },
  max3000: function(val) {
    return (val || '').length <= 3000 || '3000文字以内にしてください'
  },
}


//***************************************************
// Choose corresponding alert on token verification error from server
//
function CheckTokenError(errorResponse) {
  if (!errorResponse.response) {
    alert($literals.MESSAGE.programError + errorResponse)
    return false
  }

  switch (errorResponse.response.status) {
    case $literals.STATUS_CODE.errTokenExpired:
      alert($literals.MESSAGE.tokenExpired)
      return true //認証エラー

    case $literals.STATUS_CODE.errTokenInvalid:
      alert($literals.MESSAGE.tokenInvalid + '\n\n' + errorResponse.response.status)
      return true //認証エラー

    case $literals.STATUS_CODE.unauthorized:
      alert($literals.MESSAGE.failedAuthorization + '\n\n' + errorResponse.response.status)
      return true //認証エラー

    case $literals.STATUS_CODE.dbErrNoRowsAffected:
      alert($literals.MESSAGE.infoNoRowsAffected + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.dbErrNoTargetToCast:
      alert($literals.MESSAGE.infoNoTargetToCast + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errFileuploadfailed:
      alert($literals.MESSAGE.failedUploadImage + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.lineErrPushMessageFailed:
      alert($literals.MESSAGE.failedPushMessageFailed + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.lineErrInsightfailed:
      alert($literals.MESSAGE.failedLineTargetreach + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.lineErrBroadcastfailed:
      alert($literals.MESSAGE.failedLineBroadcast + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errSendMailFailed:
      alert($literals.MESSAGE.failedSendMail + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errSendActivateMailFailed:
      alert($literals.MESSAGE.failedSendActivateMail + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errSendInquiryMailFailed:
      alert($literals.MESSAGE.failedSendInquiryMail + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errSendMailmagaFailed:
      alert($literals.MESSAGE.failedSendMailMagazine + '\n\n' + errorResponse.response.status)
      return false

    case $literals.STATUS_CODE.errCsvReaderFailed:
      alert($literals.MESSAGE.failedUploadCsv + '\n\n' + errorResponse.response.status)
      return false

    default:
      alert($literals.MESSAGE.failedApiRequest + errorResponse.response.data + errorResponse.response.status)
      return false
  }
}

//***************************************************
// File upload
//
function HandleChangeImg(vm, e) {
  vm.message = ''
  vm.uploadedImage = ''
  vm.file = e.target.files[0]

  // Validation
  if (
    vm.file.type.indexOf('jpeg') < 0 &&
    vm.file.type.indexOf('jpg') < 0 &&
    vm.file.type.indexOf('png') < 0
  ) {
    alert($literals.MESSAGE.validationFileType)
  } else if (vm.file.size > 1 * 1024 * 1024) {
    alert($literals.MESSAGE.validationFileSize)
  } else {
    let reader = new FileReader()
    reader.readAsDataURL(vm.file)
    reader.onload = e => {
      vm.uploadedImage = e.target.result
    }
    return
  }

  e.target.value = ''
  vm.uploadedImage = ''
}

//***************************************************
// Multiple File upload
//
function HandleChangeMultipleImg(vm, e) {
  vm.uploadedImages.length = 0

  e.target.files.forEach((file, i) => {
    vm.files[i] = file

    // Validation
    if (
      vm.files[i].type.indexOf('jpeg') < 0 &&
      vm.files[i].type.indexOf('jpg') < 0 &&
      vm.files[i].type.indexOf('png') < 0
    ) {
      alert($literals.MESSAGE.validationFileType)
    } else if (vm.files[i].size > 1 * 1024 * 1024) {
      alert($literals.MESSAGE.validationFileSize)
    } else {
      let reader = new FileReader()
      reader.readAsDataURL(vm.files[i])
      reader.onload = e => {
        vm.uploadedImages.push(e.target.result)
      }
    }
  })
}

//***************************************************
// Convert and validate phone number in form input
//
function ConvertPhone(str) {
  // Doublt byte char conversion
  let converted = str
  if (str.match(/[０-９ー＋]/)) {
    converted = str.replace(/[ー]/g, '-').replace(/[０-９＋]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
  }
  return converted
}

//***************************************************
// Convert form data into hash object
//
function FormDataToObj(formData) {
  const hash = {}

  for (let item of [...formData.entries()]) {
    hash[item[0]] = item[1]
  }

  return hash
}

//***************************************************
//
// Export classes
//
//***************************************************
// Class for shop admin API calls
//
export class ApiTool {
  constructor(endpoint, shopData, token) {
    this.endpoint = endpoint
    this.shopId = shopData ? shopData.shop_id : 0
    this.token = token
  }

  // Set token
  setToken(token) {
    this.token = token
  }

  //
  // オフィシャルHP用公開情報 GET APIコール（認証無し）
  //
  getReqSitePublic(infoType, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: 'GET' },
        ...{ url: this.endpoint + 'site/' + this.shopId + '/' + infoType },
        ...{ params: queries }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // アドミン用公開情報 GET APIコール（認証無し）
  //
  getReqSystemPublic(infoType, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: 'GET' },
        ...{ url: this.endpoint + infoType },
        ...{ params: queries }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // GET APIコール（認証あり）
  //
  getReqWithAuth(infoType, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: 'GET' },
        ...{ url: this.endpoint + this.shopId + '/' + infoType },
        ...{ params: queries },
        ...{ headers: {Authorization: 'Bearer ' + this.token} }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // API req: フォームデータで更新
  //
  apiReqWithData(method, infoType, data) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: method },
        ...{ url: this.endpoint + this.shopId + '/' + infoType },
        ...{ data: data },
        ...{ headers: {Authorization: 'Bearer ' + this.token} }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // LINEプッシュメッセージ用のAPIコール
  //
  pushLineMessage(customerId, messageText) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: this.endpoint + this.shopId + '/customer/' + customerId + '/pushmessage/',
        headers: {
          Authorization: 'Bearer ' + this.token
        },
        data: JSON.stringify({ message: messageText })
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (CheckTokenError(error)) {
            reject('reset')
          }
          else if (error.response.status === $literals.STATUS_CODE.dbErrNoRowsAffected) {
            alert($literals.MESSAGE.failedApiDataNotFound + error.response.data + error.response.status)
          }
          else if (error.response.status === $literals.STATUS_CODE.lineErrPushMessageFailed) {
            alert($literals.MESSAGE.failedPushMessageFailed + error.response.data + error.response.status)
          }
          else {
            alert($literals.MESSAGE.failedPushLineToCustomer + error.response.data + error.response.status)
          }
        })
    })
  }
}

//***************************************************
// Class for business hour methods
//
export class BizHour {
  constructor(shopData) {
    this.openingHour = shopData.opening_hour
    this.closingHour = shopData.closing_hour

    this.openingHourNum = Number(shopData.opening_hour.split(':')[0])
    this.closingHourNum = Number(shopData.closing_hour.split(':')[0])

    this.closingHour24 = this.closingHourNum <= this.openingHourNum ? this.closingHourNum + 24 + ':00' : this.closingHour

    // For fullcalendar slot hours(24 hour = '25:00')
    this.slotOpenHHMM = this.openingHourNum - 1 + ':00'
    this.slotCloseHHMM = this.closingHourNum <= this.openingHourNum ? this.closingHourNum + 1 + 24 + ':00' : this.closingHourNum + 1 + ':00'
  }

  //
  // 日付から営業開始日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizOpening(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() <= this.closingHourNum) {
        tmp = moment(datetime).subtract(1, 'd')
      }
    } else {
      //閉店時刻が同日（24時前閉店）の場合
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    return moment(
      moment(tmp).format('YYYY-MM-DD') + ' ' + this.openingHour
    ).format('YYYY-MM-DD HH:mm')
  }

  //
  // 日付から営業終了日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizClosing(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    //同日閉店で閉店時刻後の場合
    else if (datetime.getHours() >= this.closingHourNum) {
      tmp = moment(datetime).add(1, 'd')
    }
    return moment(
      moment(tmp).format('YYYY-MM-DD') + ' ' + this.closingHour
    ).format('YYYY-MM-DD HH:mm') //.add(1, 'm')
  }

  //
  // 引数dtがbaseDate日の営業時間内かどうか
  // return Boolean
  isInBizHours(dt, baseDate) {
    const datetime = new Date(dt)

    const opening = this.getBizOpening(baseDate)
    const closing = this.getBizClosing(baseDate)

    return moment(datetime).diff(opening) >= 0 && moment(datetime).diff(closing) <= 0 ? true : false
  }

  //
  // 営業時間のHour数値を配列で返す
  //
  getBizHourArray() {
    const hourArray = []

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      for (let i = this.openingHourNum; i <= 23; i++) {
        hourArray.push(i)
      }
      for (let i = 0; i <= this.closingHourNum; i++) {
        hourArray.push(i)
      }
    } else {
      for (let i = this.openingHourNum; i <= this.closingHourNum; i++) {
        hourArray.push(i)
      }
    }
    return hourArray
  }

  //
  // フォーム用の分数値を配列で返す
  //
  getBizMinArray() {
    const minArray = []

    for (let i = 0; i <= 45; i += 15) {
      minArray.push(i)
    }
    return minArray
  }
}
