<template>
  <v-app>    <!-- ヘッダー（トップバー） -->
    <nav-drawer
      v-if="isLoggedin && shopData"
      :isloggedin="isLoggedin"
      :isLiff="isLIFF"
      :userProfile="userProfile"
      :shopData="shopData"
      @logout="logout"
    ></nav-drawer>

    <home-top-bar
      v-if="!isLoggedin"
      :isloggedin="isLoggedin"
      :isLiff="isLIFF"
      :userProfile="userProfile"
      :shopData="shopData"
      @basicLogin="basicLogin($event)"
      @lineLogin="lineLogin"
      @logout="logout"
    ></home-top-bar>

    <!-- コンテンツ -->
    <v-main
      :style="$vuetify.theme.isDark || {background: 'var(--main-bg)'}"
      class="justify-center"
    >
      <router-view
        v-if="(isAuthReady && shopData) || isAuthNotNeeded"
        :isloggedin="isLoggedin"
        :isLiff="isLIFF"
        :apiAdmin="apiAdmin"
        :shopData="shopData"
        @basicLogin="basicLogin($event)"
        @reset="logout"
      ></router-view>
    </v-main>

    <!-- フッター -->
    <footer-bar
      v-if="!isLoggedin"
    ></footer-bar>

    <!-- 店舗選択フォーム -->
    <form-select-shop
      ref="refFormSelectShop"
      :shops="shopData"
      @form-submitted="shopSelected($event)"
    ></form-select-shop>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="true"
    >
      {{ loadingMessage }}
    </loader>
  </v-app>
</template>

<script>
import axios from 'axios'
import liff from '@line/liff'

import $literals from '@/literals.js'
import { API_ENDPOINT, LIFF_ID } from '@/literals.js'
import { CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import FormSelectShop from '@/components/_FormSelectShop.vue'
import NavDrawer from '@/components/_NavDrawer.vue'
import HomeTopBar from '@/components/HomeTopBar.vue'
import Footer from '@/components/_Footer.vue'

export default {
  name: 'App',
  components: {
    'loader': Loader,
    'form-select-shop': FormSelectShop,
    'nav-drawer': NavDrawer,
    'home-top-bar': HomeTopBar,
    'footer-bar': Footer,
  },

  data() {
    return {
      // host: document.location.hostname,
      env: process.env.NODE_ENV,
      isLoggedin: false,
      userProfile: { id: null, displayName: '' },
      isLIFF: false,
      shopDataArray: [],
      selectedShopId: null,
      loading: false,
      loadingMessage: 'システム初期化中'
    }
  },

  computed: {
    apiAdmin() {
      return API_ENDPOINT + '/admin/'
    },
    apiLineAdminAuth() {
      return this.apiAdmin + 'auth/line/'
    },
    apiBasicAdminAuth() {
      return this.apiAdmin + 'auth/basic/'
    },
    shopData() {
      return this.shopDataArray.find( shopData => shopData.shop_id == this.selectedShopId )
    },
    isAuthReady() {
      return this.$route.meta.requiresAuth && this.isLoggedin
    },
    isAuthNotNeeded() {
      return !this.$route.meta.requiresAuth
    }
  },

  created() {
    //リダイレクトが設定されている
    if (sessionStorage.getItem('redirectTo')) {
      this.$router.push(sessionStorage.getItem('redirectTo'))
    }

    this.initLiff()
  },

  methods: {
    //
    //Basicログイン
    //
    basicLogin(credential) {
      if (!credential.loginId || !credential.password) {
        alert($literals.MESSAGE.validationLoginCreadential)
        return
      }
      // Authentication with basic credential
      this.authWithCredential(credential)
    },

    //
    //LINEログイン
    //
    lineLogin() {
      liff.login()
    },

    //ログアウト
    logout() {
      if (liff.isLoggedIn()) liff.logout()
      this.isLoggedin = false
      this.userProfile.displayName = ''
      this.userProfile.userId = ''
      this.shopDataArray.length = 0
      this.selectedShopId = null
      sessionStorage.clear()

      if (this.$route.path !== '/') this.$router.push('/')
    },

    //
    //LIFF初期化
    //
    initLiff() {
      return liff.init({
          liffId: LIFF_ID
        })
        .then(() => {
          //LINEログインしてる場合
          if (liff.isLoggedIn()) {
            //LIFF = LINEトーク画面内
            this.isLIFF = liff.isInClient()

            //LINEプロフィール取得
            liff.getProfile().then(prof => {
              sessionStorage.setItem('redirectTo', '/shop-admin/dashboard')

              this.userProfile = prof
              sessionStorage.setItem('userProfile', JSON.stringify(prof))

              //ストレージが無いなら
              if (!sessionStorage.getItem('shopDataArray') || !sessionStorage.getItem('selectedShopId')) {
                this.authWithCredential({ idToken: liff.getIDToken() })

              //あるならシステムログイン済み
              } else {
                this.isLoggedin = true
                this.selectedShopId = sessionStorage.getItem('selectedShopId')
                this.shopDataArray = JSON.parse(sessionStorage.getItem('shopDataArray'))
              }
            })
            .catch(error => {
              alert($literals.MESSAGE.failedLineProfile + error.response.data + '\n' + error.response.status)
              this.logout()
            })
          //LINEログインしてない場合
          } else {
            if (sessionStorage.getItem('serverToken') !== null &&
                sessionStorage.getItem('shopDataArray') !== null &&
                sessionStorage.getItem('selectedShopId') !== null)
            {
              //ストレージあるならシステムログイン不要
              this.isLoggedin = true
              this.selectedShopId = sessionStorage.getItem('selectedShopId')
              this.shopDataArray = JSON.parse(sessionStorage.getItem('shopDataArray'))
            }
          }
        })
        .catch(error => {
          alert($literals.MESSAGE.failedAuthInitialization + error.response.data + '\n' + error.response.status)
          liff.closeWindow()
        })
    },

    //
    //システムログイン＆店舗データの取得
    //
    authWithCredential(authObj) {
      this.loading = true
      const args = { method: 'POST' }

      if (authObj.idToken) {
        args.url = this.apiLineAdminAuth + authObj.idToken
      } else {
        args.url = this.apiBasicAdminAuth + authObj.loginId
        args.params = { password: authObj.password }
      }

      axios(args)
        .then(response => {
          sessionStorage.setItem('isLoggedin', true)
          sessionStorage.setItem('serverToken', response.data.token)

          this.shopDataArray = response.data.shop_data
          sessionStorage.setItem('shopDataArray', JSON.stringify(this.shopDataArray))

          this.isLoggedin = true

          //複数店ならフォームで店舗選択
          if (this.shopDataArray.length > 1) {
            this.$refs.refFormSelectShop.open(this.shopDataArray)
          } else {
            this.selectedShopId = this.shopDataArray[0].shop_id
            sessionStorage.setItem('selectedShopId', this.selectedShopId)

            //リダイレクトが設定されている場合
            if (sessionStorage.getItem('redirectTo')) {
              this.$router.push(sessionStorage.getItem('redirectTo'))
            }
          }

        })
        .catch(error => {
          if (CheckTokenError(error)) {
            this.logout()
          }
        })
        .then(() => this.loading = false )
    },

    //
    //フォームで店舗選択後の処理
    //
    shopSelected(selectedShopId) {
      if (selectedShopId) {
        this.selectedShopId = selectedShopId
        sessionStorage.setItem('selectedShopId', selectedShopId)

        //リダイレクトが設定されている場合
        if (sessionStorage.getItem('redirectTo')) {
          this.$router.push(sessionStorage.getItem('redirectTo'))
        }
      } else {
        alert($literals.MESSAGE.failedShopData)
        this.logout()
      }
    },
  }
};
</script>

<style>
/***************************************
            Custom Property
****************************************/
:root {
  --nav-drawer-width: 220px;

  --main-bg: #f0f2f5;
  --content-bg-diff: #EEEEEE;
  --content-bg-dark-diff: #222222;
  --content-border-dark: #272727;

  --grey-lighten: #BDBDBD;
}

/***************************************
            Vuetify Modifier
****************************************/
.v-application p {
  margin-bottom: 0;
}
.theme--dark.v-card.v-sheet {
    border: thin solid var(--content-border-dark);
}
.v-main__wrap {
  width: 100%;
  max-width: 1800px !important;
}

/***************************************
             共通コンテンツ
****************************************/
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

img {
  max-width: 100%;
}
ul {
  list-style: none;
}
body {
  width: 100%;
  /* max-width: 1380px; */
  font-family: こぶりなゴシック W1 JIS2004, "Helvetica Neue", Helvetica, Arial,
    Verdana, Roboto, 游ゴシック, "Yu Gothic", 游ゴシック体, YuGothic,
    "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", メイリオ,
    Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1a1a;
  margin: 0 auto;
  background: #fafafa;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.logo-font {
  font-family: "Fredoka One", "Kosugi Maru", sans-serif;
}
</style>
