var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","width":"220","permanent":"","mini-variant":_vm.openDrawer},on:{"update:miniVariant":function($event){_vm.openDrawer=$event},"update:mini-variant":function($event){_vm.openDrawer=$event}}},[_c('v-sheet',{staticClass:"menu-header px-4 d-flex align-center",class:{'pl-2': _vm.openDrawer}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openDrawer = !_vm.openDrawer}}},[(_vm.openDrawer)?_c('v-icon',{attrs:{"color":"accent"},domProps:{"textContent":_vm._s(_vm.mdiIconName('hamburger'))}}):_c('v-icon',{attrs:{"color":"accent"},domProps:{"textContent":_vm._s(_vm.mdiIconName('backburger'))}})],1),_c('v-spacer'),_c('system-menu',{attrs:{"shopData":_vm.shopData},on:{"logout":function($event){return _vm.$emit('logout')}}})],1),_c('v-divider'),_c('v-list',{staticClass:"pt-0"},[_c('v-list-group',{attrs:{"value":_vm.isItemSelected(_vm.shopAdminMenu)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName('account-tie'))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v("店舗管理")])])]},proxy:true}])},_vm._l((_vm.shopAdminMenu),function(menu){return _c('v-list-item',{key:menu.id,staticClass:"pl-6 pr-3",attrs:{"to":{
          path: '/' + menu.slug,
          params: {
            slug: menu.slug,
            id: menu.id,
            title: menu.title,
            isloggedin: _vm.isloggedin
          }
        }}},[(_vm.lockedMenu(menu.lockAt))?_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',[_vm._v("mdi-lock-outline")])],1):_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName(menu.iconName))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline",class:!_vm.lockedMenu(menu.lockAt) && 'font-weight-bold'},[_vm._v(_vm._s(menu.title))])])],1)}),1),_c('v-list-group',{attrs:{"value":_vm.isItemSelected(_vm.siteUpdateMenu)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName('monitor-dashboard'))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v("ＨＰ管理")])])]},proxy:true}])},_vm._l((_vm.siteUpdateMenu),function(menu){return _c('v-list-item',{key:menu.id,staticClass:"pl-6 pr-3",attrs:{"to":{
          path: '/' + menu.slug,
          params: {
            slug: menu.slug,
            id: menu.id,
            title: menu.title,
            isloggedin: _vm.isloggedin
          }
        }}},[_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName(menu.iconName))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v(_vm._s(menu.title))])])],1)}),1),_c('v-list-group',{attrs:{"value":_vm.isItemSelected(_vm.siteSettingMenu)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName('application-cog'))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v("ＨＰ基本設定")])])]},proxy:true}])},_vm._l((_vm.siteSettingMenu),function(menu){return _c('v-list-item',{key:menu.id,staticClass:"pl-6 pr-3",attrs:{"to":{
          path: '/' + menu.slug,
          params: {
            slug: menu.slug,
            id: menu.id,
            title: menu.title,
            isloggedin: _vm.isloggedin
          }
        }}},[_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName(menu.iconName))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v(_vm._s(menu.title))])])],1)}),1),_c('v-list-group',{attrs:{"value":_vm.isItemSelected(_vm.shopSettingMenu)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName('cog'))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v("店舗基本設定")])])]},proxy:true}])},_vm._l((_vm.shopSettingMenu),function(menu){return _c('v-list-item',{key:menu.id,staticClass:"pl-6",attrs:{"to":{
          path: '/' + menu.slug,
          params: {
            slug: menu.slug,
            id: menu.id,
            title: menu.title,
            isloggedin: _vm.isloggedin
          }
        }}},[(_vm.lockedMenu(menu.lockAt))?_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',[_vm._v("mdi-lock-outline")])],1):_c('v-list-item-icon',{staticClass:"ml-2 mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mdiIconName(menu.iconName))}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-overline",class:!_vm.lockedMenu(menu.lockAt) && 'font-weight-bold'},[_vm._v(_vm._s(menu.title))])])],1)}),1)],1),(!_vm.openDrawer)?_c('v-sheet',{staticClass:"mt-auto px-3 pb-1",attrs:{"color":"primary"}},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('v-img',{staticClass:"px-10 rounded",attrs:{"contain":"","src":require("@/assets/logo_mini.png")}})],1),_c('v-card',{staticClass:"notification-window px-2 py-1",attrs:{"outlined":""}},[_c('h5',{staticClass:"pb-2 text-caption text-center font-weight-bold"},[_vm._v("システムに関するお知らせ")]),_vm._l((_vm.notificationRows),function(row){return _c('v-card-text',{key:row.id,staticClass:"pa-1"},[_c('p',{staticClass:"text-body-2",domProps:{"innerHTML":_vm._s(row.content)}}),_c('v-divider',{staticClass:"my-3"})],1)})],2),_c('copy-right',{staticClass:"mt-3"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }